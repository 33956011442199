import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { Formik, Field } from "formik";
import AttachmentIcon from "../static/images/AttachmentIcon.svg";
import { api_url } from "../constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = Yup.object().shape({
  name: Yup.string().required("Name is a required field"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters"),
  phone_number: Yup.number()
    .required("Phone number is a required field")
    .integer("Input a valid phone number"),
});

const Register = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [token, setToken] = useState(localStorage.getItem("accessToken"));
  const [name, setName] = useState(state?.name);

  useEffect(() => {
    if (!name) {
      fetch(`${api_url}/api/user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then((response) => {
          if (!response.ok) {
            navigate("/login");
          }
          return response.json();
        })
        .then((data) => {
          setName(data.name);
        });
    }
  }, []);


  const [selectedFile1, setSelectedFile1] = useState();
  const [selectedFile2, setSelectedFile2] = useState();
  const [selectedFile3, setSelectedFile3] = useState();
  const [selectedFile4, setSelectedFile4] = useState();
  const [selectedFile5, setSelectedFile5] = useState();
  const [selectedFile6, setSelectedFile6] = useState();

  const submitDriverInfo = (values) => {
    console.log(values);
    const formData = new FormData();

    Object.keys(values).forEach((name) => {
      formData.append(name, values[name]);
    });

    fetch(`${api_url}/api/driver/info`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            // Throw an error with the response body
            toast.error(text, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        navigate("/waiting");
      });
  };

  return (
    <>
      {/* Wrapping form inside formik tag and passing our schema to validationSchema prop */}
      <Formik
        enableReinitialize={true}
        // validationSchema={schema}
        initialValues={{
          name: state?.name,
          car_plate_number: "",
          user_status: "",
          car_model: "",
          car_color: "",
        }}
        onSubmit={submitDriverInfo}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <div className="register">
            <div className="form">
              {/* Passing handleSubmit parameter tohtml form onSubmit property */}
              <form noValidate onSubmit={handleSubmit}>
                <span className="page-title">Senior Registration</span>
                {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                <p className="huh">Name</p>
                <input
                  type="name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  className="form-control inp_text"
                  id="name"
                  disabled
                />
                <p className="huh">Status</p>
                <ul className="choose-user">
                  <li>
                    <Field type="radio" id="user" name="user_status" value="STUDENT" />
                    <label for="user">Student</label>
                  </li>
                  <li>
                    <Field type="radio" id="other" name="user_status" value="STAFF" />
                    <label for="other">Staff</label>
                  </li>
                </ul>
                <p className="huh">Car Model</p>
                <input
                  type="car_model"
                  name="car_model"
                  placeholder="Perodua Myvi"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.car_model}
                  className="form-control inp_text"
                  id="car_model"
                />
                <p className="huh">Car colour</p>
                <input
                  type="car_color"
                  name="car_color"
                  placeholder="White"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.car_color}
                  className="form-control inp_text"
                  id="car_color"
                />
                <p className="huh">Number Plate</p>
                <input
                  type="car_plate_number"
                  name="car_plate_number"
                  placeholder="HWE 1234"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.car_plate_number}
                  className="form-control inp_text"
                  id="car_plate_number"
                />
                <p className="huh"></p>
                <div className="attachment">
                  <input
                    id="profile_picture"
                    type="file"
                    isMulti={false}
                    onChange={(e) => {
                      // Object is possibly null error w/o check
                      // TODO: Check file size and type
                      if (e.currentTarget.files) {
                        setSelectedFile1(true);
                        setFieldValue("profile_picture", e.currentTarget.files[0]);
                      }
                    }}
                  />
                  <label for="profile_picture" className="file_input">
                    <i className="fa-solid fa-upload"></i>
                    {!selectedFile1 && "Upload Profile Photo"}
                    {selectedFile1 && "Saved"}
                  </label>
                </div>
                <p className="huh"></p>
                <div className="attachment">
                  <input
                    id="license"
                    type="file"
                    isMulti={false}
                    onChange={(e) => {
                      // Object is possibly null error w/o check
                      if (e.currentTarget.files) {
                        console.log(e.currentTarget.files);
                        setSelectedFile2(true);
                        setFieldValue("license", e.currentTarget.files[0]);
                      }
                    }}
                  />
                  <label for="license" className="file_input">
                    <i className="fa-solid fa-upload"></i>
                    {!selectedFile2 && "Upload Driver license"}
                    {selectedFile2 && "Saved"}
                  </label>
                </div>
                <p className="huh"></p>
                <div className="attachment">
                  <input
                    id="car_pictures"
                    type="file"
                    isMulti={false}
                    onChange={(e) => {
                      // Object is possibly null error w/o check
                      if (e.currentTarget.files) {
                        console.log(e.currentTarget.files);
                        setSelectedFile3(true);
                        setFieldValue("car_pictures", e.currentTarget.files[0]);
                      }
                    }}
                  />
                  <label for="car_pictures" className="file_input">
                    <i className="fa-solid fa-upload"></i>
                    {!selectedFile3 && "Upload Car Photos"}
                    {selectedFile3 && "Saved"}
                  </label>
                </div>
                <p className="huh"></p>
                <div className="attachment">
                  <input
                    id="card_matric"
                    type="file"
                    isMulti={false}
                    onChange={(e) => {
                      // Object is possibly null error w/o check
                      if (e.currentTarget.files) {
                        console.log(e.currentTarget.files);
                        setSelectedFile4(true);
                        setFieldValue("card_matric", e.currentTarget.files[0]);
                      }
                    }}
                  />
                  <label for="card_matric" className="file_input">
                    <i className="fa-solid fa-upload"></i>
                    {!selectedFile4 && "Upload Card Matric"}
                    {selectedFile4 && "Saved"}
                  </label>
                </div>
                <p className="huh"></p>
                <div className="attachment">
                  <input
                    id="insurance"
                    type="file"
                    isMulti={false}
                    onChange={(e) => {
                      // Object is possibly null error w/o check
                      if (e.currentTarget.files) {
                        console.log(e.currentTarget.files);
                        setSelectedFile5(true);
                        setFieldValue("insurance", e.currentTarget.files[0]);
                      }
                    }}
                  />
                  <label for="insurance" className="file_input">
                    <i className="fa-solid fa-upload"></i>
                    {!selectedFile5 && "Upload Insurance"}
                    {selectedFile5 && "Saved"}
                  </label>
                </div>
                <p className="huh"></p>
                <div className="attachment">
                  <input
                    id="road_tax"
                    type="file"
                    isMulti={false}
                    onChange={(e) => {
                      // Object is possibly null error w/o check
                      if (e.currentTarget.files) {
                        console.log(e.currentTarget.files);
                        setSelectedFile6(true);
                        setFieldValue("road_tax", e.currentTarget.files[0]);
                      }
                    }}
                  />
                  <label for="road_tax" className="file_input">
                    <i className="fa-solid fa-upload"></i>
                    {!selectedFile6 && "Upload Road Tax"}
                    {selectedFile6 && "Saved"}
                  </label>
                </div>
                {/* Click on submit button to submit the form */}
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        )}
      </Formik>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default Register;
